// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Position = {
  "SERVER": "SERVER",
  "FOH_TEAM_MEMBER": "FOH_TEAM_MEMBER",
  "BOH_TEAM_MEMBER": "BOH_TEAM_MEMBER",
  "COOK": "COOK",
  "CHEF": "CHEF",
  "BARTENDER": "BARTENDER",
  "BUSSER": "BUSSER",
  "DISHWASHER": "DISHWASHER"
};

const ShiftType = {
  "DAY_LUNCH_SHIFT": "DAY_LUNCH_SHIFT",
  "DINNER_EVENING_SHIFT": "DINNER_EVENING_SHIFT",
  "ALL_DAY_EVENT": "ALL_DAY_EVENT"
};

const NotificationType = {
  "JOBACCEPTED": "JOBACCEPTED",
  "JOBDECLINED": "JOBDECLINED",
  "JOBFILLED": "JOBFILLED",
  "RESTAURANTNOTE": "RESTAURANTNOTE",
  "SYSTEMMSG": "SYSTEMMSG"
};

const UserRoles = {
  "SYSTEMADMIN": "SYSTEMADMIN",
  "RESTAURANTADMIN": "RESTAURANTADMIN",
  "MOBILEUSER": "MOBILEUSER"
};

const ApplicationStatus = {
  "SUBMITTED": "SUBMITTED",
  "PENDING": "PENDING",
  "UNDERREVIEW": "UNDERREVIEW",
  "COMPLETED": "COMPLETED",
  "ACCEPTED": "ACCEPTED",
  "REFUSED": "REFUSED",
  "CANCELED": "CANCELED"
};

const JobStatus = {
  "OPEN": "OPEN",
  "FILLED": "FILLED",
  "CANCELLED": "CANCELLED"
};

const UserStatus = {
  "ONLINE": "ONLINE",
  "OFFLINE": "OFFLINE",
  "HIDDEN": "HIDDEN",
  "WORKING": "WORKING",
  "VACATION": "VACATION"
};

const { JobTemplate, Notifications, Application, UserAttributes, RestaurantAttributes, Job, Restaurants, Users, Blog, Post, Comment } = initSchema(schema);

export {
  JobTemplate,
  Notifications,
  Application,
  UserAttributes,
  RestaurantAttributes,
  Job,
  Restaurants,
  Users,
  Blog,
  Post,
  Comment,
  Position,
  ShiftType,
  NotificationType,
  UserRoles,
  ApplicationStatus,
  JobStatus,
  UserStatus
};