// File: src/components/Sidebar.js

import React from 'react';
import { FiHome, FiBriefcase, FiUser, FiSettings, FiMessageCircle, FiMail, FiUsers } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function Sidebar({ currentUser }) {
  const menuItems = [
    { label: 'Dashboard', icon: <FiHome />, link: '/' },
    { label: 'Restaurants', icon: <FiBriefcase />, link: '/restaurants' },
    { label: 'Jobs', icon: <FiMessageCircle />, link: '/jobs' },
    { label: 'Applications', icon: <FiMail />, link: '/applications' },
    { label: 'Notifications', icon: <FiUser />, link: '/notifications' },
    { label: 'Settings', icon: <FiSettings />, link: '/settings' },
  ];

  // Conditionally add User Management item for SYSTEMADMIN
  if (currentUser && currentUser.role === 'SYSTEMADMIN') {
    menuItems.push({
      label: 'User Management',
      icon: <FiUsers />,
      link: '/user-management',
    });
  }

  return (
    <div className="w-64 h-screen bg-darkBg text-white fixed top-0 left-0 p-6 flex flex-col space-y-6">
      <div className="text-2xl font-bold text-primary mb-10">GIGGz Portal</div>
      <nav className="space-y-4">
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            className="flex items-center space-x-3 p-3 rounded-lg hover:bg-accent transition-colors"
          >
            <span className="text-xl">{item.icon}</span>
            <span className="text-lg">{item.label}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
}

export default Sidebar;
